const plPL = {
  signIn: 'Zaloguj się',
  signUp: 'Zarejestruj się',
  signOut: 'Wyloguj się',
  logging: 'Logowanie',
  returnToHome: 'Powrót na stronę główną',
  char_one: 'znak',
  char_few: 'znaki',
  char_many: 'znaków',
  char_other: 'znak',
  cancel: 'Anuluj',
  confirm: 'Potwierdź',
  homePage: 'Strona główna',
  noData: 'Brak danych',
  pageNotFound: 'Strona nie istnieje.',
  intlDisplayNames: '{{value, displayNames}}',
  intlList: '{{value, list}}',
  intlDateTime: '{{value, datetime}}',
  intlDateTimeRange: '{{value, dateTimeRange}}',
  loginAs: 'Zalogowano jako:',
  yourId: 'Twój identyfikator:',
  websiteName: 'Portal Kandydata',
  addRecruitment: 'Dodaj stopień lub turę rekrutacji',
  yes: 'Tak',
  no: 'Nie',
  uploadFile: 'Wgraj plik',
  changeFile: 'Nadpisz plik',
  downloadFile: 'Pobierz plik',

  pageTitle: {
    defaultTitle: '',
    personalData: 'Dane osobowe',
  },

  genders: {
    female: 'Kobieta',
    male: 'Mężczyzna',
    other: 'Inna',
    notStated: 'Nie podano',
  },

  aria: {
    userMenu: 'menu użytkownika',
    languageButton: 'otwórz menu wyboru języka',
    back: 'cofnij',
    togglePassword: 'zmień widoczność hasła',
    hamburger: 'otwórz menu nawigacyjne',
    close: 'zamknij',
    edit: 'edytuj',
    delete: 'usuń',
    show: 'pokaż',
    clear: 'wyczyść',
  },

  form: {
    save: 'Zapisz',
    required: 'To pole jest wymagane',
    noWhitespace: 'Pole nie może być puste',
    pattern: 'Wprowadź prawidłowy {{what}}',
    betweenLen: 'Tekst powinien zawierać między {{min}}, a {{max}} $t(char, {"count": {{max}} })',
    minLen: 'Co najmniej {{value}} $t(char, {"count": {{value}} })',
    maxLen: 'Maksymalnie {{value}} $t(char, {"count": {{value}} })',
    min: 'Wartość nie może być mniejsza niż {{value}}',
    max: 'Wartość nie może być większa niż {{value}}',
    unicodeLettersAndSpaces: 'Tylko litery i spacje',
    telephone: 'Niepoprawny numer telefonu',
    email: 'Niepoprawny adres email',
    password: 'Nieprawidłowe hasło',
    passwordMatch: 'Hasła się nie zgadzają',
    personName: 'Nieprawidłowe znaki',
    dialCodeRequired: 'Podaj kod kierunkowy',
    ReCAPTCHAValidationFaild: 'Błąd walidacji ReCAPTCHA',
    win1250only: 'Pole zawiera niedozwolone znaki',
    pesel: 'Niewłaściwy pesel',
    lettersNumbers: 'Używaj jedynie liter i liczb',
    lettersNumbersAndSlash: 'Używaj jedynie liter, liczb i znaku /',
    polishPostalCode: 'Nieprawidłowy format kodu pocztowego. Spróbuj użyć formatu 00-000',
  },

  apiErrors: {
    default: 'Coś poszło nie tak, spróbuj ponownie.',
    unauthorized: 'Twoja sesja wygasła, zaloguj się ponownie.',
    forbidden: 'Nie posiadasz uprawnień do tego zasobu.',
  },

  passwordHints: {
    passwordShouldHas: 'Hasło powinno posiadać:',
    charLength: 'między {{min}}, a {{max}} $t(char, {"count": {{max}} })',
    lowercaseLetter: 'jedną małą literę',
    capitalLetter: 'jedną dużą literę',
    digit: 'jedną cyfrę',
    specialSign: 'jeden znak specjalny, np. @#!$%',
    confirmPasswordError: 'Hasła nie pasują do siebie',
  },

  indexPage: {
    firstLineTitle: 'Zapisy kandydatów na studia',
    personalData: 'Dane osobowe',
    photo: 'Zdjęcie legitymacyjne',
    messages: 'Komunikaty',
    accountActivation: 'Wyślij aplikację',
    forForeignCandidate: 'kandydatów z zagranicy',
    forPolishCandidate: 'Polaków',
    for: 'Dla',
    forPolishAndForeignCandidate: 'Polaków i kandydatów z zagranicy',
    recruitment: 'Rekrutacja',
    startRecruitment: 'Rekrutuj się',
    myRecruitment: 'Aktywne rekrutacje',
    pastRecruitment: 'Zakończone rekrutacje',
    schools: 'Ukończona szkoła',
    courses: 'Wybór kierunków',
    qualification: 'Wyniki kwalifikacji',
    studentZone: 'Strefa studenta',
    FIRST_STAGE: 'pierwsza tura rekrutacji',
    SECOND_STAGE: 'druga tura rekrutacji',
    THIRD_STAGE: 'trzecia tura rekrutacji',
    FOURTH_STAGE: 'czwarta tura rekrutacji',
    FIRST_DEGREE: 'pierwszy stopień studiów',
    SECOND_DEGREE: 'drugi stopień studiów',
    THIRD_DEGREE: 'trzeci stopień studiów',
    admitted: 'Zostałaś/eś przyjety na studia',
    notAdmitted: 'Nie zostałaś/eś przyjety na studia',
    qualificationResult: 'Wyniki rekrutacji',
    activateStudentZone: 'Aktywuj konto uczelniane',
    takeNextStage: 'Weż udział w kolejnej turze rekrutacji',
    noAvailableRecruitments: 'Brak dostępnych rekrutacji',
    button1: 'Zapoznaj się z zasadami rekrutacji',
    button2: 'Poznaj naszą uczelnię',
    lead: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam eget justo sit amet ligula congue sodales. Mauris consectetur metus nec ipsum porttitor tempus. Donec tempor vitae libero in tempus.',
    noActiveRecruitments:
      'Brak aktywnych rekrutacji. Jeżeli chcesz rozpocząć rekrutację, dodaj stopień lub turę rekrutacji używając zielonego przycisku.',
    successRecruitmentModal: {
      congratulations: 'Gratulujemy!',
      acceptedToStudy: 'Zostałaś/eś przyjęty na studia',
      note: 'Możesz już pobrać zaświadczenie o przyjęciu na studia i aktywować konto uczelniane.',
    },
    pastRecruitmentAlerts: {
      studentZoneActive: 'Konto uczelniane aktywne. Zapraszamy na inaugurację roku akademiciego.',
      noNextStage: 'To była ostatnia tura. Zapraszamy do naboru w kolejnej rekrutacji.',
      dateOfNextStage: 'Kolejna tura rozpocznie się',
      hasNexStage: 'Bierzesz udział w kolejnej turze rekrutacji.',
      hadNextStage: 'Wziąłeś udział w kolejnej turze rekrutacji.',
    },
    warnings: {
      completeData: 'Uzupełnij dane',
    },
  },

  authRoutes: {
    returnToSignIn: 'Powrót do strony logowania',
  },

  loginPage: {
    emailAddress: 'Email',
    password: 'Hasło',
    confirmPassword: 'Potwierdź hasło',
    forgotPassword: 'Nie pamiętasz hasła?',
    dontHaveAccount: 'Nie masz konta? Zarejestruj się',
    alreadyHaveAccount: 'Posiadasz konto?',
    login: 'Logowanie',
    rememberMe: 'Zapamiętaj mnie',
  },

  register: {
    registration: 'Rejestracja',
    submitEmail: 'Wyślij email z linkiem aktywacyjnym',
    agreement1:
      'Potwierdzam zapoznanie się z zasadami korzystania z elektronicznej rejestracji w Portalu Kandydata Politechniki Łódzkiej. ',
    agreement2:
      'Potwierdzam zapoznanie się z celem i podstawą prawną dotyczącą przetwarzania danych osobowych oraz informacjami o administratorze danych osobowych.',
    agreement3:
      'Jestem świadomy, że ponoszę pełną odpowiedzialność prawną i wszelkie konsekwencje, ze skreśleniem z listy osób przyjętych włącznie, za podanie nieprawidłowych danych potrzebnych w postępowaniu kwalifikacyjnym na studia w Politechnice Łódzkiej.',
    agreement4: 'Wyrażam zgodę na otrzymywanie informacji drogą elektroniczną.',
    agreement5:
      'Wyrażam zgodę na potwierdzenie przez Politechnikę Łódzką autentyczności załączonych w toku rekrutacji dokumentów dotyczących przebiegu mojego kształcenia w jednostkach bądź instytucjach, które je wydały celem potwierdzenia prawidłowości oświadczonego przeze mnie stanu faktycznego w przebiegu procesu rekrutacji.',
    agreement6: 'Wyrażam zgodę na otrzymywanie informacji związanych z promocją Politechniki Łódzkiej drogą elektroniczną.',
    link1: 'Zasady',
    link2: 'RODO',
    checkAll: 'Zaznacz wszystkie zgody',
  },

  emailConfirmationPage: {
    resendEmail: 'Wyślij email ponownie',
    emailSent: 'Email został ponownie wysłany.',
    accountRegistered:
      'Twoje konto zostało zarejestrowane. Na adres email podany podczas rejestracji otrzymasz link do aktywacji konta.',
  },

  accountVerificationPage: {
    title: 'Aktywacja konta',
    submitButton: 'Aktywuj konto',
    successfulActivation: 'Twoje konto zostało aktywowane.',
    errorMessage: 'Nie udało się zweryfikować adresu email.',
    waitForVerification: 'Trwa weryfikacja adresu email',
  },

  recoverPasswordPage: {
    recoverPassword: 'Odzyskiwanie hasła',
    emailSent: 'Email został wysłany. Aby zmienić hasło proszę kliknąć w link znajdujący się w treści wiadomości.',
    submitEmail: 'Wyślij email z linkiem',
  },

  resetPasswordPage: {
    resetPassword: 'Zmień hasło',
    savePassword: 'Zapisz nowe hasło',
    passwordChanged: 'Hasło zostało zmienione, możesz teraz zalogować się nowymi danymi',
  },
  footer: {
    universityName: 'Dział Rekrutacji Politechniki Łódzkiej',
    universityAddress: 'ul. Radwańska 29, budynek A13',
    universityAddressCaption: '(dodatkowe wejście od ul. Stefanowskiego 22)',
    recruitmentWebsite: 'Strona rekrutacji',
    contact1: 'Kontakt dla Polaków - I i II stopień',
    contact2: 'Kontakt dla cudzoziemców - I i II stopień',
    contact3: 'Kontakt dla kandydatów - III stopień',
    stepByStep: 'Rekrutacja KROK po KROKU',
    rules: 'Zasady elektronicznej rejestracji',
    gdpr: 'Klauzula RODO',
  },
  backendErrors: {
    'RC-1001': 'Nieoczekiwany błąd',
    'RC-1101': 'Nieprawidłowy token uwierzytelniający',
    'RC-1102': 'Brak dostępu',
    'RC-1103': 'Niewłaściwe dane logowania',
    'RC-1201': 'Nieprawidłowa treść żądania',
    'RC-1202': 'Niekompletne żądanie',
    'RC-1301': 'Nieprawidłowa wartość pola',
    'RC-1302': 'Nieprawidłowe wartości w powiązanych polach',
    'RC-1402': 'Zasobu nie można modyfikować',
    'RC-1403': 'Zasób już istnieje',
    'RC-1404': 'Nie znaleziono zasobu',
    'RC-1501': 'Nie udało się przetworzyć odpowiedzi z webserwisu',
    'RC-1502': 'Błąd żądania do webserwisu',
    'RC-1601': 'Błąd operacji bazodanowych',
    'RC-1602': 'Naruszenie spójności danych bazodanowych',
    unknownError: 'Niespodziewany błąd',
    unknownErrorDetails: 'Spróbuj raz jeszcze lub skontaktuj się z administratorem',
  },
  successResponse: {
    defaultSuccess: 'Sukces',
    defaultMessage: 'Operacja zakończona powodzeniem',
    saveSuccess: 'Sukces',
    saveMessage: 'Dane zapisane poprawnie',
    updateSuccess: 'Sukces',
    updateMessage: 'Dane zostały poprawnie zaktualizowane',
    deleteSuccess: 'Sukces',
    deleteMessage: 'Dane usunięte poprawnie',
  },

  personalData: {
    pesel: 'PESEL',
    name: 'Imię',
    secondName: 'Drugie/kolejne imie',
    surname: 'Nazwisko',
    familyName: 'Nazwisko rodowe kandydata',
    fatherName: 'Imię ojca',
    motherName: 'Imie matki',
    motherFamilyName: 'Nazwisko panieńskie matki',
    gender: 'Płeć',
    genderSelect: {
      male: 'Mężczyzna',
      female: 'Kobieta',
    },
    hasPesel: 'Posiadam numer PESEL',
    passportNumber: 'Seria i numer paszportu',
    passportCountry: 'Nazwa państwa wydania paszportu',
    nationality: 'Narodowość',
    citizenshipPrimary: 'Obywatelstwo',
    citizenshipSecondary: 'Drugie obywatelstwo',
    citizenshipAdditionalInfo: 'Dodatkowe informacje dotyczące obywatelstwa',
    citizenshipAdditionalInfoSelect: {
      s1: 'Posiadam ważną Kartę Polaka lub decyzję w sprawie stwierdzenia polskiego pochodzenia wydaną przez Konsula RP',
      s2: 'Jestem stypendystą NAWA / Ubiegam się o stypendium NAWA',
      s3: 'Posiadam zezwolenie na pobyt stały lub jestem rezydentem długoterminowym Unii Europejskiej',
      s4: 'Posiadam status uchodźcy/korzystam z ochrony czasowej/ochrony uzupełniającej na terytorium RP',
      s5: 'Jestem obywatelem Unii Europejskiej, Konfederacji Szwajcarskiej lub EFTA',
      s6: 'Posiadam Certyfikat znajomości języka polskiego na poziomie C1 wydany w Polsce przez Komisję Państwową',
      s7: 'Jestem cudzoziemcem będącym małżonkiem, wstępnym (rodzicem) lub zstępnym (dzieckiem) obywatela RP, mieszkającym na terytorium RP',
      s8: 'Posiadam zezwolenie na pobyt czasowy wydane w celu prowadzenia badań naukowych lub w związku z innymi okolicznościami',
      s9: 'Pozostali cudzoziemcy niespełniający powyższych warunków',
    },
    birthDate: 'Data urodzenia',
    birthCountry: 'Kraj urodzenia',
    birthPlace: 'Miejsce urodzenia',
    firstPersonInFamilyToStudy: 'Czy jest Pan/Pani pierwszą osobą w swojej najbliższej rodzinie, która chce podjąć studia?',
    contactAddress: 'Adres kontaktowy',
    registeredAddress: 'Adres zamieszkania',
    otherMailingAddress: 'Inny adres do korespondencji',
    healthInformation: 'Dodatkowe informacje o stanie zdrowia',
    certificateOfDisability: 'Posiadam orzeczenie o stopniu niepełnosprawności lub dokument równoważny',
    personWithSpecialNeeds: 'Jestem osobą ze szczególnymi potrzebami (bez orzeczenia, ale z niepełnosprawnością)',
    country: 'Państwo',
    city: 'Miasto',
    voivodeship: 'Województwo',
    postalCode: 'Kod pocztowy',
    street: 'Ulica lub nazwa miejscowości',
    streetNumber: 'Numer domu',
    apartmentNumber: 'Numer mieszkania',
    phone: 'Numer telefonu',
    typeOfOrigin: 'Pochodzenie - miasto/wieś',
    typeOfOriginSelect: {
      city: 'miasto',
      village: 'wieś',
    },
    files: {
      header: 'Wymagane pliki',
      description: 'Załącz skany poniższych dokumentów.',
      quality:
        'Skany muszą być czytelne oraz kompletne. Nieczytelność skanów może być podstawą do nieuwzględnienia Cię w procesie kwalifikacji na studia w Politechnice Łódzkiej.',
      formats: 'Skany dokumentów w formacie PDF, JPG, JPEG lub PNG.',
      maxFileSize: 'Maksymalny rozmiar pojedynczego pliku - 5 MB.',
      title: 'Nazwij pliki zgodnie z ich zawartością.',
      uploadedTooltip: 'Plik oczekuje na weryfikację',
      fileAcceptedTooltip: 'Plik został zweryfikowany i zaakceptowany',
      notAcceptedTooltip: 'Plik został odrzucony',
      uploadedChip: 'Oczekuje na weryfikację',
      fileAcceptedChip: 'Zaakceptowany',
      notAcceptedChip: 'Odrzucony',
      uploadLock: 'Zweryfikowane pliki nie mogą być nadpisywane',

      PASSPORT: 'Skan paszportu',
      VISA: 'Skan wizy',
      POLES_CARD: 'Karta Polaka lub decyzja w sprawie stwierdzenia polskiego pochodzenia',
      NAWA_SCHOLARSHIP: 'Potwierdzenie bycia stypendystą NAWA /ubiegania się o stypendium NAWA ',
      PERMANENT_RESIDENCE_PERMIT: 'Zezwolenie na pobyt stały lub bycie rezydentem Unii Europejskiej',
      REFUGEE_STATUS: 'Potwierdzenie statusu uchodźcy/korzystania z ochrony czasowej/ochrony uzupełniającej na terytorium RP',
      UE_SWISS_EFTA: 'Potwierdzenie bycia obywatelem UE, Konfederacji Szwajcarskiej lub EFTA',
      POLISH_C1: 'Certyfikat znajomości języka polskiego na poziomie C1',
      POLISH_CITIZENSHIP_FAMILY_CONFIRMATION: 'Potwierdzenie bycia małżonkiem, wstępnym lub zstępnym obywatela RP',
      TEMPORARY_RESIDENCE_RESEARCH:
        'Potwierdzenie zezwolenia na pobyt czasowy w celu prowadzenia badań naukowych lub innych okoliczności',
      TEMPORARY_RESIDENCE_FAMILY_REUNION: 'Zezwolenie na pobyt czasowy w celu połączenia się z rodziną',
    },
  },
  candidateCourses: {
    addCourse: 'Dodaj kierunek',
    myCourses: 'Wybrane kierunki',
    courseName: 'Nazwa',
    courseType: 'Tryb',
    isPaid: 'Opłacone',
    price: 'Cena',
    addCourseToList: 'Wybierz kierunek',
    operations: '',
    courses: 'Kierunki',
    noAvailableCourses: 'Brak aktywnych kierunków na rekrutację',
    noSelectedCourses: 'Nie wybrałes jeszcze kierunków, na które chcesz się rekrutować',
    deleteButton: 'Usuń kierunek',
    priorityButton: 'Ustaw priorytet',
    paymentButton: 'Opłać kierunek',
    continuePaymentButton: 'Dokończ płatność',
    pendingPaymentButton: 'Płatność rozpoczęta',
    tooltip: {
      paid: 'Kierunek został opłacony',
      not_paid: 'Kierunek nie został jeszcze opłacony',
      priority: 'Kierunek ustawiony jako priorytetowy',
      not_priority: 'Kierunek niepriorytetowy',
    },
  },
} as const;

Object.freeze(plPL);

export default plPL;
